import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser(
    $first_name: String!
    $last_name: String!
    $euid: String!
    $roles: [Role]!
    $is_disabled: Boolean
    $phone_no: String!
    $retail_location: ID!
  ) {
    adminCreateUser(
      data: {
        first_name: $first_name
        last_name: $last_name
        euid: $euid
        roles: $roles
        is_disabled: $is_disabled
        phone_no: $phone_no
        retail_location: $retail_location
      }
    ) {
      first_name
      last_name
      is_disabled
      roles
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $first_name: String
    $last_name: String
    $roles: [Role]
    $euid: String
    $is_disabled: Boolean
    $id: ID!
    $phone_no: String
    $retail_location: ID
  ) {
    adminUpdateUser(
      data: {
        first_name: $first_name
        last_name: $last_name
        roles: $roles
        is_disabled: $is_disabled
        euid: $euid
        phone_no: $phone_no
        retail_location: $retail_location
      }
      userId: $id
    ) {
      id
      first_name
      last_name
      roles
      message
    }
  }
`;
