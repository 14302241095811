import { MoreOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Popover, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import Portal from '../../components/Portal';
import SearchComponent from '../../components/SearchComponent';
import TableComponent from '../../components/TableComponent';
import AddUserModal from './AddUserModal';
import { UPDATE_USER } from './graphql/Mutations';
import { GET_USERS } from './graphql/Queries';

const UserList = () => {
  const {
    state: { pageSize }
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState();

  const initialUserFilter = {
    skip: 0,
    limit: 10
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [userFilter, setUserFilter] = useState(initialUserFilter);

  const [updateUserMutate, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      onError() {}
    }
  );

  const [fetchUserData, { loading, data, refetch }] = useLazyQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.adminUsersList?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  useEffect(() => {
    fetchUserData({ variables: { filter: userFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setShowModal(true);
  };

  const handleTableChange = (pagination) => {
    const { current, pageSize: paginationPageSize } = pagination;
    const skip = (current - 1) * paginationPageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    const newFilter = {
      ...userFilter,
      limit: paginationPageSize,
      skip
    };
    setUserFilter(newFilter);
    fetchUserData({ variables: { filter: newFilter } });
  };

  const handleAddEditUser = () => {
    setShowModal(true);
  };

  const handleUserStatus = async (e, editUserData) => {
    e?.preventDefault();
    const response = await updateUserMutate({
      variables: { is_disabled: !editUserData.is_disabled, id: editUserData.id }
    });
    if (response?.data?.adminUpdateUser) {
      refetch();
    }
  };

  const onSearchChange = async (value) => {
    const newFilter = {
      ...userFilter,
      skip: 0,
      search: value
    };
    const newinitial = {
      ...initialPaginationValue,
      current: 1
    };
    setUserFilter(newFilter);
    setPaginationProp(newinitial);
    fetchUserData({ variables: { filter: newFilter } });
  };

  const refetchUserDetails = () => {
    setUserFilter({
      skip: 0,
      limit: 10
    });
    setPaginationProp(initialPaginationValue);
    fetchUserData({ variables: { filter: { ...initialUserFilter } } });
  };

  const renderActionButtons = (editUserData) => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="user-table-edit-btn"
          className="b-0"
          onClick={() => {
            setUserData(editUserData);
            handleAddEditUser();
          }}
        >
          Edit
        </Button>
        <Button
          id="user-table-status-btn"
          className="b-0"
          onClick={(e) => handleUserStatus(e, editUserData)}
        >
          {editUserData?.is_disabled ? 'Mark Active' : 'Mark Inactive'}
        </Button>
      </div>
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      render: (text, record) => {
        return `${record?.first_name} ${record?.last_name}`;
      }
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      render: (role, record) => {
        return role.map((roleData) => (
          <Tag key={`${roleData}-${record?.id}`}>{roleData?.toUpperCase()}</Tag>
        ));
      }
    },
    {
      title: 'EUID',
      dataIndex: 'euid'
    },
    {
      title: 'Contact Number',
      dataIndex: 'phone_no',
      render: (phoneNo) => {
        return <span>{phoneNo}</span>;
      }
    },
    {
      title: 'Location',
      dataIndex: 'retail_location',
      render: (retailLocation) => {
        return (
          <span>
            {retailLocation?.state
              ? `${retailLocation?.state} - ${retailLocation?.city}`
              : retailLocation?.city}
          </span>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'is_disabled',
      render: (isdisabled) => {
        if (!isdisabled) {
          return <Tag color="green">Active</Tag>;
        }

        return <Tag color="red">Deactive</Tag>;
      }
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '10%',
      render: (id, record) => {
        return (
          <Popover placement="bottom" content={renderActionButtons(record)}>
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  return (
    <Card>
      <Portal portalId="header-title">
        <h1 className="m-0">User Management</h1>
      </Portal>
      {showModal && (
        <AddUserModal
          showModal={showModal}
          setShowModal={setShowModal}
          setUserData={setUserData}
          userData={userData}
          isUpdate={!!userData}
          refetchUserDataWithInitialValues={refetchUserDetails}
          refetchUserData={refetch}
        />
      )}

      <div className="d-flex align-center justify-between">
        <div className="mb-10 width-percent-20">
          <SearchComponent
            id="search-container-id"
            name="Users"
            getData={onSearchChange}
          />
        </div>
        <Button type="primary" onClick={handleAdd}>
          Add User
        </Button>
      </div>

      <hr />
      <TableComponent
        loadingData={loading || updateUserLoading}
        columns={columns}
        data={data?.adminUsersList?.users || []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj.id}
      />
    </Card>
  );
};

export default UserList;
