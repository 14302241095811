import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_USERS = gql`
  query users($filter: UserFilter!) {
    adminUsersList(filter: $filter) {
      count
      users {
        id
        first_name
        last_name
        euid
        phone_no
        is_disabled
        roles
        retail_location {
          id
          description
          city
          state
        }
      }
    }
  }
`;

export const GET_LOCATIONS = gql`
  query retailLocations {
    retailLocations {
      id
      description
      address_1
      address_2
      store_num
      store_division
      city
      state
    }
  }
`;
