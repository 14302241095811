import { useMutation, useQuery } from '@apollo/client';
import { Form, Input, Select, Switch } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { map, values } from 'lodash';
import React, { useState } from 'react';
import { REGEX, ROLES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { CREATE_USER, UPDATE_USER } from './graphql/Mutations';
import { GET_LOCATIONS } from './graphql/Queries';

const { Option } = Select;
const { required } = formValidatorRules;

const AddUserModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    userData,
    setUserData,
    refetchUserDataWithInitialValues,
    refetchUserData
  } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [createUserMutate] = useMutation(CREATE_USER, {
    onError() {}
  });

  const { data, loading: locationsLoading } = useQuery(GET_LOCATIONS, {
    fetchPolicy: 'network-only'
  });

  const [updateUserMutate] = useMutation(UPDATE_USER, {
    onError() {}
  });

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setUserData();
  };

  const onUserSubmitFinish = async (formData) => {
    setLoading(true);
    const variables = isUpdate
      ? {
          ...formData,
          id: userData.id
        }
      : {
          ...formData,
          is_disabled: !formData.is_disabled
        };

    try {
      if (isUpdate) {
        const response = await updateUserMutate({
          variables: { ...variables }
        });
        if (response?.data?.adminUpdateUser) {
          setUserData();
          form.resetFields();
          setShowModal(false);
          refetchUserData();
        }
        return response;
      }
      const response = await createUserMutate({
        variables: { ...variables }
      });
      if (response?.data?.adminCreateUser) {
        setUserData();
        form.resetFields();
        setShowModal(false);
        refetchUserDataWithInitialValues();
      }
    } catch (error) {
      setLoading(false);
      return error;
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setShowModal(true);
    form.submit();
  };

  return (
    <Modal
      title={isUpdate ? 'Edit user' : 'Add user'}
      visible={showModal}
      confirmLoading={loading}
      onOk={handleAdd}
      className="dialog"
      okText={isUpdate ? 'Save' : 'Add'}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        initialValues={{
          ...userData,
          retail_location: userData?.retail_location?.id
        }}
        layout="vertical"
        onFinish={onUserSubmitFinish}
      >
        <Form.Item rules={[required]} name="first_name" label="First Name">
          <Input allowClear />
        </Form.Item>
        <Form.Item rules={[required]} name="last_name" label="Last name">
          <Input allowClear />
        </Form.Item>
        <Form.Item
          rules={[required]}
          name="euid"
          label="EUID "
          normalize={(value) => (value || '').toUpperCase()}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          rules={[
            required,
            () => ({
              validator(rule, value) {
                if (value) value.split(' ').join('');
                const numberPattern = REGEX.PHONE;
                if (value && !numberPattern.test(value)) {
                  return Promise.reject(
                    new Error('should be a valid phone number')
                  );
                }
                return Promise.resolve();
              }
            })
          ]}
          name="phone_no"
          label="Phone Number"
          className="phone-number"
        >
          <Input />
        </Form.Item>
        <Form.Item rules={[required]} name="roles" label="Position">
          <Select className="mr-3" placeholder="Position" allowClear>
            {values(ROLES).map((role) => (
              <Option key={role} value={role}>
                {role}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item rules={[required]} name="retail_location" label="Location">
          <Select
            className="mr-3"
            placeholder="Location"
            allowClear
            loading={locationsLoading}
          >
            {map(data?.retailLocations, (location) => {
              return (
                <Option key={location?.id} value={location?.id}>
                  {location?.state
                    ? `${location?.state} - ${location?.city}`
                    : location?.city}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {!isUpdate && (
          <Form.Item name="is_disabled" label="Status" valuePropName="checked">
            <Switch />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default AddUserModal;
